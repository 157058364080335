export default class CruMenu {
    IDENTIFIER = {
        CHAT_ID: 'chat-now',
        AI_CHAT: 'ai-chat',
        TRADE_LIST_ID: 'trade-list-excel',
        WHISKY_CASK_ID: 'whisky-casks',
        BUY: 'buy',
        B2B_LISTING: 'b2b-listing'
    }
    activeMenu = []
    menuTreeNodes = {}
    menuIdentifierNodes = {}
    customMenu = null

    initialize() {
        this.initSidebar()
        this.hideTradeListDownload()
        this.initChatMenu()
        this.initAIChat()
        //this.hideWhiskyCaskMenu()
        this.initMenuHoverAndClick()
    }

    initMenuHoverAndClick() {
        const subDomain = location.host.substring(0, 2)
        const cookieDomain = window.location.host.replace(subDomain, '')
        setTimeout(() => {
            if (
                $j('.top-level-container:first-child').is(':hover') &&
                $j('.top-level-container:first-child .cur-active-menu').length == 0
            ) {
                $j('.top-level-container:first-child').addClass('active-menu')
            }
        }, 300)

        $j('.top-level-container')[0].on('mouseover', function () {
            $j(this).find('.cur-active-menu').length == 0
                ? $j(this).addClass('active-menu')
                : $j(this).removeClass('active-menu')
        })

        $j('.top-level-container')[0].on('mouseleave', function () {
            $j(this).removeClass('active-menu')
        })

        $j('.menu-fl').on('click', function () {
            setCookie('menuId', $j(this).attr('data-menu-id'), 1, cookieDomain)
        })

        $j('.menu-sl').on(
            'click',
            { cookieDomain: cookieDomain },
            this.secondaryMenuClick.bind(this)
        )

        $j('.secondary-menu-pills li').on(
            'click',
            { cookieDomain: cookieDomain, loadingClass: 'pill-clicked' },
            this.secondaryMenuClick.bind(this)
        )

        $j('.menu-tl').on('click', function ($event) {
            $event.stopPropagation()
            setCookie('menuId', $j(this).attr('data-menu-id'), 1, cookieDomain)
        })
    }

    secondaryMenuClick($event) {
        $event.stopPropagation()
        setCookie(
            'menuId',
            $event.currentTarget.attributes['data-menu-id'].value,
            1,
            $event.data.cookieDomain
        )
        let loadingClass = $event.data.loadingClass
        if (loadingClass) {
            $event.currentTarget.classList.add(loadingClass)
        }
    }

    mainContainerMargin() {
        if ($j('.level1-submenu-show').length > 0 && window.innerWidth > 1050) {
            $j('.main-container').css('margin-top', '52px')
        }
    }
    activateFirstLevelmenu($menu) {
        $j(`[data-menu-id="${$menu.id}"]`)
            .get()
            .forEach($elem => {
                const $flMenu = $j($elem)
                $flMenu.attr('data-is-vertical')
                    ? $flMenu.addClass('fl-item-active')
                    : $flMenu.addClass('level1-submenu-show')
            })
    }

    activateSecondLevelMenu($menu) {
        $j(`[data-menu-id="${$menu.id}"]`)
            .get()
            .forEach($elem => {
                $j($elem).addClass('cur-active-menu')
                if ($j('.cur-active-menu.pills-item').length > 0) {
                    setTimeout(() => {
                        document.querySelector('.cur-active-menu.pills-item').scrollIntoView({
                            inline: 'center',
                            behavior: 'smooth',
                            block: 'nearest'
                        })
                    }, 200)
                }
            })
    }

    activateMenuBreadCrumb($menu, $childMenu = null) {
        if ($menu) {
            switch ($menu.level) {
                case 1:
                    this.activateFirstLevelmenu($menu, $childMenu)
                    this.mainContainerMargin()
                    break
                case 2:
                    this.activateSecondLevelMenu($menu)
                    break
                default:
                    break
            }
            this.activateMenuBreadCrumb(this.getAncestorMenu($menu), $menu)
        }
    }

    setCustomMenu($menu) {
        this.customMenu = $menu
    }

    getCustomMenu() {
        return this.customMenu ? this.getMenuNodeById(this.customMenu.id) : null
    }

    processMenuBreadCrumbs($menuTree) {
        if (!this.isSecondaryMenuHidden()) {
            setTimeout(() => {
                this.initMenuBreadCrumbs($menuTree)
                if (this.getCustomMenu()) {
                    this.activateMenuBreadCrumb(this.getCustomMenu())
                } else {
                    this.activateMenuBreadCrumbs()
                }
            }, 0)
        }
    }

    activateMenuBreadCrumbs() {
        // Make the third level first
        let $activeMenus = this.activeMenu.sort(
            (a, b) => b.isExact - a.isExact || +b.level - +a.level
        )
        const $isExactArr = $activeMenus.filter(x => x.isExact == true)
        if ($isExactArr.length > 1 && getCookie('menuId')) {
            $activeMenus = $activeMenus.filter(x => x.id == getCookie('menuId'))
        }

        this.activateMenuBreadCrumb($activeMenus[0])

        // By default open the secondary menu
        if ($activeMenus.length == 0) {
            this.activateMenuBreadCrumb(this.getMenuNodeByIdentifier(this.IDENTIFIER.BUY))
        }
    }

    /**
     * To be called only after init
     * @param {Menu} $menu
     * @returns
     */
    getAncestorMenu($menu) {
        return this.getMenuNodeById($menu.parent)
    }

    /**
     * To be called only after init
     * @param {Menu} $menu
     * @returns
     */

    getMenuNodeById($menuId) {
        return $menuId ? this.menuTreeNodes[$menuId] ?? null : null
    }

    /**
     * To be called only after init
     * @param {string} $identifier
     * @returns
     */

    getMenuNodeByIdentifier($identifier) {
        return $identifier ? this.menuIdentifierNodes[$identifier] ?? null : null
    }

    isApproximateMatch($urlToMatch, $curPath = window.location.pathname) {
        const $urlPath =
            $urlToMatch.indexOf('?') > -1
                ? $urlToMatch.substr(0, $urlToMatch.indexOf('?'))
                : $urlToMatch
        return $urlPath == $curPath
    }

    isExactMatch(
        $urlToMatch,
        $curUrl = window.location.href.substring(window.location.origin.length)
    ) {
        return $curUrl == $urlToMatch
    }

    isSecondaryMenuHidden() {
        return this.isHomepage() || this.isCheckoutPage() || this.isDeliveryCheckoutPage()
    }

    isHomepage() {
        return this.isApproximateMatch(`/${window.location.search}`)
    }

    isCheckoutPage() {
        return this.isApproximateMatch(`/checkout/onepage/${window.location.search}`)
    }

    isDeliveryCheckoutPage() {
        return this.isApproximateMatch(`/deliver/arrangeDelivery/${window.location.search}`)
    }

    appendMenuTreeNode($menu, $parentId, $level) {
        this.menuTreeNodes[$menu.id] = {
            id: $menu.id,
            title: $menu.title,
            url: $menu.url,
            parent: +$parentId,
            menu_type: $menu.menu_type,
            level: $level,
            url_type: $menu.url_type,
            identifier: $menu.identifier
        }

        if ($menu.identifier) {
            this.menuIdentifierNodes[$menu.identifier] = this.menuTreeNodes[$menu.id]
        }
    }

    appendActiveMenu($menu) {
        if ($menu.url) {
            if (this.isExactMatch($menu.url)) {
                this.activeMenu.push({ ...this.getMenuNodeById($menu.id), isExact: true })
            } else if (this.isApproximateMatch($menu.url)) {
                this.activeMenu.push({ ...this.getMenuNodeById($menu.id), isExact: false })
            }
        }
    }

    initMenuBreadCrumbs($menu, $parentId = -1, $level = 1) {
        for (let $i = 0; $i < $menu.length; $i++) {
            const $menuChildren = $menu[$i].children ?? []
            this.appendMenuTreeNode($menu[$i], $parentId, $level)
            this.appendActiveMenu($menu[$i])
            if ($menuChildren.length > 0) {
                this.initMenuBreadCrumbs($menuChildren, $menu[$i].id, $level + 1)
            }
        }
    }

    initChatMenu() {
        setTimeout(
            $j(`a[data-menu-id="${this.IDENTIFIER.CHAT_ID}"]`)
                .get()
                .forEach($menu => {
                    $j($menu).click(window.cruMenu.openChat)
                }),
            100
        )
    }

    initAIChat() {
        setTimeout(
            $j(`a[data-menu-id="${this.IDENTIFIER.AI_CHAT}"]`)
                .get()
                .forEach($menu => {
                    $j($menu).click(() => {
                        $j('#ai-chat-modal').delay(400).fadeIn()
                    })
                }),
            100
        )
    }

    initSidebarChatMenu() {
        setTimeout(() => {
            let $menu = $j(`.sub-menu-wrapper a[data-menu-id="${this.IDENTIFIER.CHAT_ID}"]`)
            $j($menu).click(window.cruMenu.openChat)
        })
    }

    initSidebarAIChatMenu() {
        setTimeout(() => {
            let $menu = $j(`.sub-menu-wrapper a[data-menu-id="${this.IDENTIFIER.AI_CHAT}"]`)
            $j($menu).click(() => {
                $j('#ai-chat-modal').delay(400).fadeIn()
            })
        })
    }

    hideTradeListDownload() {
        // If customer is not trade client, then hide it
        const allowedGroups = [2, 7]
        const allowedCustomerId = [16]

        let isExcelMenuVisible =
            allowedGroups.includes(customerGroup) || allowedCustomerId.includes(tradeCustomerId)

        if (!isExcelMenuVisible) {
            // hide the visible menu
            $j(`a[data-menu-id="${this.IDENTIFIER.TRADE_LIST_ID}"]`)
                .get()
                .forEach($menu => {
                    const excelMenu = $j($menu)
                    if (excelMenu) {
                        excelMenu.remove()
                    }
                })
        }
    }

    hideWhiskyCaskMenu() {
        // If customer is not trade client, then hide it
        const notAllowedGroup = [0]

        let isWhiskyCaskVisible = !notAllowedGroup.includes(customerGroup)

        if (!isWhiskyCaskVisible) {
            // hide the visible menu
            $j(`a[data-menu-id="${this.IDENTIFIER.WHISKY_CASK_ID}"]`)
                .get()
                .forEach($menu => {
                    const whiskyMenu = $j($menu)
                    if (whiskyMenu) {
                        whiskyMenu.hide()
                    }
                })
        }
    }

    openChat() {
        if (
            typeof window._chatlio === 'object' &&
            !Array.isArray(window._chatlio) &&
            window._chatlio !== null
        ) {
            window._chatlio.show({ expanded: true })
        }
    }

    initSidebar() {
        $j('#country-picker').on('click', () => {
            $j('.country-wrapper').show()
            setTimeout(() => {
                $j('.country-wrapper').toggleClass('is-visible')
            }, 300)
        })
        $j('.back-currency').on('click', () => {
            $j('.country-wrapper').toggleClass('is-visible')
            setTimeout(() => {
                $j('.country-wrapper').hide()
            }, 300)
        })

        $j('#notification-display').on('click', () => {
            $j('.notification-wrapper').show()
            setTimeout(() => {
                $j('.notification-wrapper').toggleClass('is-visible')
            }, 300)
        })
        $j('.back-notification').on('click', () => {
            $j('.notification-wrapper').toggleClass('is-visible')
            setTimeout(() => {
                $j('.notification-wrapper').hide()
            }, 300)
        })

        $j('#header-toggle').on('click', () => {
            this.toggleMenu()
        })
        $j('.level-1 > li > a').on('click', e => {
            const target = e.target
            const siblingList = target.nextElementSibling
            if (siblingList) {
                e.preventDefault()
                var cloneSiblingList = siblingList.cloneNode(true)
                $j('.menu-title-1').html(target.innerHTML.trim())
                $j('.list-wrapper:nth-child(2) .sub-menu-wrapper').html(cloneSiblingList)
                $j('.list-wrapper:nth-child(2)').show()
                $j('.list-wrapper:nth-child(2)').addClass('is-visible')
                setTimeout(() => {
                    $j('#country-picker').hide()
                    $j('#notification-display').hide()
                    $j('.menu-title').hide()
                    this.initSidebarChatMenu() //register click for sidebar
                    this.initSidebarAIChatMenu()
                }, 1000)
            }
        })

        $j('.list-wrapper:nth-child(2)').on('click', e => {
            const target = e.target
            if (target.tagName.toLowerCase() === 'a' && target.nextElementSibling) {
                const siblingList = target.nextElementSibling
                e.preventDefault()
                target.classList.add('is-active')
                const cloneSiblingList = siblingList.cloneNode(true)
                $j('.menu-title-2').html(target.innerHTML.trim())
                $j('.list-wrapper:nth-child(3) .sub-menu-wrapper').html(cloneSiblingList)
                $j('.list-wrapper:nth-child(3)').show()
                $j('.list-wrapper:nth-child(3)').addClass('is-visible')
            }
        })

        $j('.list-wrapper:nth-child(3)').on('click', e => {
            const target = e.target
            if (target.tagName.toLowerCase() === 'a' && target.nextElementSibling) {
                const siblingList = target.nextElementSibling
                e.preventDefault()
                target.classList.add('is-active')
                const cloneSiblingList = siblingList.cloneNode(true)
                $j('.menu-title-3').html(target.innerHTML.trim())
                $j('.list-wrapper:nth-child(4) .sub-menu-wrapper').html(cloneSiblingList)
                $j('.list-wrapper:nth-child(4)').show()
                $j('.list-wrapper:nth-child(4)').addClass('is-visible')
            }
        })

        $j('.back-one-level').on('click', e => {
            if ($j(e.target.nextElementSibling).hasClass('menu-title-1')) {
                $j('#country-picker').show()
                $j('#notification-display').show()
                $j('.menu-title').show()
            }
            const parent = e.target.closest('.list-wrapper')
            parent.classList.remove('is-visible')
            setTimeout(() => {
                parent.hide()
            }, 300)
        })
    }

    isHamBurgerOpen() {
        return $j('#nav-icon').hasClass('hamburger')
    }

    toggleMenu() {
        const $hamburgerNotifCount = $j('#hamburger-notif-count')
        // Toggle only if notification is available
        if ($hamburgerNotifCount.data('notif-count')) {
            if ($hamburgerNotifCount.hasClass('show')) {
                $hamburgerNotifCount.removeClass('show').addClass('hide')
            } else {
                $hamburgerNotifCount.removeClass('hide').addClass('show')
            }
        }
        $j('#nav-icon').toggleClass('hamburger close')
        $j('nav').toggleClass('active')
        $j('.country-wrapper').hide()
        $j('.notification-wrapper').hide()
        $j('.list-wrapper:nth-child(2)').hide()
        $j('.list-wrapper:nth-child(3)').hide()
        $j('.list-wrapper:nth-child(4)').hide()
        $j('.list-wrapper:nth-child(2)').removeClass('is-visible')
        $j('.list-wrapper:nth-child(3)').removeClass('is-visible')
        $j('.list-wrapper:nth-child(4)').removeClass('is-visible')

        if ($j('nav').hasClass('active')) {
            $j('body').addClass('lock-position')
        } else {
            $j('body').removeClass('lock-position')
            $j('#country-picker').show()
            $j('#notification-display').show()
            $j('.menu-title').show()
        }
    }

    postMobileNotificationDelete($notifId = null) {
        const $notificationCards = $j('*[id^=notification-card-mob-]')
        const $isHideDismissAll = $notificationCards.length == 1 || $notifId == null
        if ($notifId) {
            const $notficationCard = $j(`#notification-card-mob-${$notifId}`)
            if ($notficationCard) {
                $notficationCard.remove()
            }
        } else {
            $notificationCards.get().forEach($notificationCard => {
                $notificationCard.remove()
            })
        }

        if ($isHideDismissAll) {
            $j('#dismiss-all-btn').remove()
            $j('#no-notif-block').toggleClass('show hide')
        }
    }
}

window.cruMenu = new CruMenu()

$j(document).ready(function () {
    window.cruMenu.initialize()
})
